import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import carRentalService from "../../api/carrental";


export const getBranches = createAsyncThunk(
    "branches/get",
    async (args, thunkAPI) => {
        try {
            const response = await carRentalService.getBranches(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getAvailability = createAsyncThunk(
    "availability/get",
    async (args, thunkAPI) => {
        try {
            const response = await carRentalService.getAvailability(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getResidenceCountries = createAsyncThunk(
    "residence_countries/get",
    async (args, thunkAPI) => {
        try {
            const response = await carRentalService.getResidenceCountries(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getRateInfo = createAsyncThunk(
    "rateinfo/get",
    async (args, thunkAPI) => {
        try {
            const response = await carRentalService.getRateInfo(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createBooking = createAsyncThunk(
    "booking/create",
    async (args, thunkAPI) => {
        try {
            const response = await carRentalService.createBooking(args);
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const initialState = {
    branches: [],
    availability: {rates: [], branches: []},
    rateInfo: null,
    loading: false,
    loadingDetail: false,
    booking: null,
    residenceCountries: [],
};

const carRentalSlice = createSlice({
    name: "carrental",
    initialState,
    extraReducers: {
        [getBranches.pending]: (state, action) => {
            state.loading = true;
        },
        [getBranches.fulfilled]: (state, action) => {
            state.branches = action.payload
            state.loading = false;
        },
        [getBranches.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja staði";
            state.loading = false;
        },
        [getAvailability.pending]: (state, action) => {
            state.loading = true;
        },
        [getAvailability.fulfilled]: (state, action) => {
            state.availability = action.payload
            state.loading = false;
        },
        [getAvailability.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja bíla";
            state.loading = false;
        },
        [getRateInfo.pending]: (state, action) => {
            state.loading = true;
        },
        [getRateInfo.fulfilled]: (state, action) => {
            state.rateInfo = action.payload
            state.loading = false;
        },
        [getRateInfo.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja rateinfo";
            state.loading = false;
        },
        [createBooking.pending]: (state, action) => {
            state.loading = true;
        },
        [createBooking.fulfilled]: (state, action) => {
            state.booking = action.payload
            state.loading = false;
        },
        [createBooking.rejected]: (state, action) => {
            state.error = "Ekki tókst að búa til bókun";
            state.loading = false;
        },
        [getResidenceCountries.pending]: (state, action) => {
            state.loading = true;
        },
        [getResidenceCountries.fulfilled]: (state, action) => {
            state.residenceCountries = action.payload.residenceCountries
            state.loading = false;
        },
        [getResidenceCountries.rejected]: (state, action) => {
            state.error = "Ekki tókst að sækja búsetulönd";
            state.loading = false;
        },
    },
});

const { reducer } = carRentalSlice;
export default reducer;
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import carRentalReducer from './slices/carrental'
 

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    carrental: carRentalReducer,
})

let middleware = null
if (process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
))}

export const store = createStore(reducers, middleware)

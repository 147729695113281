import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import SearchWidget from '../../components/search-widget'

export default function Wrapper(props) {
    return (
        <Provider store={store}>
            <SearchWidget {...props}/>
        </Provider>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/js/bootstrap.min.js";
import ReactGA from "react-ga4";
import '../sass/main.scss';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

ReactGA.initialize("G-RZ21X0PX9K");

require ('./containers/search-widget-container/index.js');
require ('./containers/results-widget-container/index.js');
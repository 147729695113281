import React, { useEffect, useState } from 'react';
import { currencyToSymbol, verboseMonth, verboseDay } from '../../utils';

export default function OverviewSidebar({ rate, selectedExtras }){
    // const [selectedExtras, setSelectedExtras] = useState({});

    const calculateTotalPriceExtras = (code) => {
        if (selectedExtras[code]) {
            return selectedExtras[code].quantity * selectedExtras[code].amount;
        }
        return 0;
    };

    const calculateTotalPrice = () => {
        let total = rate.package.vehiclePrice.total.display.amount;
        if (selectedExtras) {
            Object.keys(selectedExtras).forEach(code => {
                total += calculateTotalPriceExtras(code);
            });
        }
        return total.toFixed(2);
    };

    const format_time = (time) => {
        return `${time.slice(0,2)}:${time.slice(2)}`;
    };

    return (                
    <div className="col-lg-4 pl-lg-0 col-12">
    <div className="row car">
        <div className="col-12 mb-2">
            <div className="car-type">{rate.vehicle.type}</div>
            <div className="car-name">{rate.vehicle.name} <span>or similar</span></div>
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col pb-2">
                    <img src={rate.vehicle.images[0].url} className="img-fluid col-10" />
                </div>
            </div>
            <div className="row car-big-info col-10">
                <div className="col-5">
                    <div className="car-seats">{rate.vehicle.seats}</div>
                </div>
                <div className="col-5">
                    <div className="car-bigsuitcases text-center">{rate.vehicle.bigSuitcases}</div>
                </div>
            </div>
            <div className="row">
                <div className="col car-small-info col-10">
                    <span className="car-transmission">{rate.vehicle.transmission}</span>
                    <span className="car-airco">{rate.vehicle.airco ? "Yes" : "No"}</span>
                    <span className="car-doors">{rate.vehicle.doors}</span>
                    <span className="car-smallsuitcases">{rate.vehicle.smallSuitcases}</span>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <img className="supplier-logo" src={rate.branches[rate.pickUpBranchId].supplier.images[0].url} />
                </div>
            </div>

            <div className="row mb-5">
                <div className="col">
                    <div className="fw-bold mb-3 small-text">Included in the rate:</div>
                    {rate.package.inclusions.map((inclusion, index) => {
                        return (
                            <div className="small-text mb-1" key={index}>{inclusion.name}</div>
                        )
                    })
                    }
                </div>
            </div>
        </div>
        <div className="row">
            <div className="title mb-4">Rental information</div>
            <div className="row">
                <div className="col mb-4">
                    <div className="location">{rate.branches[rate.pickUpBranchId].location.name}</div>
                    <div className="border-bottom-divider sub-title">{rate.branches[rate.pickUpBranchId].location.country.name}</div>
                    <div className="pick-up-place small-text mb-2">{rate.branches[rate.pickUpBranchId].vehicleAt.name}</div>
                    <div className="mb-4"><a href={`https://maps.google.com/?q=${rate.branches[rate.pickUpBranchId].geoPoint.latitude},${rate.branches[rate.pickUpBranchId].geoPoint.longitude}`}>View on Google Maps</a></div>
                </div>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-6 rental-time-large">{new Date(rate.pickUpDateTime).getDate()} {verboseMonth(new Date(rate.pickUpDateTime).getMonth())}</div>
            <div className="col-6 text-end rental-time-large">{new Date(rate.pickUpDateTime).getHours()}:{("0" + String(new Date(rate.pickUpDateTime).getMinutes())).slice(-2)}</div>
            <div className="col-6 rental-time-small">{new Date(rate.pickUpDateTime).getFullYear()}</div>
            <div className="col-6 text-end rental-time-small ">{verboseDay(new Date(rate.pickUpDateTime).getDay())}</div>
            <span className="d-block divider col-10"> </span>
            <div className="col-6 rental-time-large">{new Date(rate.dropOffDateTime).getDate()} {verboseMonth(new Date(rate.dropOffDateTime).getMonth())}</div>
            <div className="col-6 text-end rental-time-large">{new Date(rate.dropOffDateTime).getHours()}:{("0" + String(new Date(rate.dropOffDateTime).getMinutes())).slice(-2)}</div>
            <div className="col-6 rental-time-small ">{new Date(rate.dropOffDateTime).getFullYear()}</div>
            <div className="col-6 text-end rental-time-small">{verboseDay(new Date(rate.dropOffDateTime).getDay())}</div>
        </div>
        <div className="row">
            <div className="col mb-5">
                <div className="fw-bold small-text pb-1">Business hours on pick-up day:</div>
                <div className="small-text pb-2">{format_time(rate.branches[rate.pickUpBranchId].openHours[new Date(rate.pickUpDateTime).getDay()].rangeStart.time)} - {format_time(rate.branches[rate.pickUpBranchId].openHours[new Date(rate.pickUpDateTime).getDay()].rangeEnd.time)}</div>
                <div className="fw-bold small-text pb-1">Business hours on drop-off day:</div>
                <div className="small-text">{format_time(rate.branches[rate.pickUpBranchId].openHours[new Date(rate.dropOffDateTime).getDay()].rangeStart.time)} - {format_time(rate.branches[rate.pickUpBranchId].openHours[new Date(rate.dropOffDateTime).getDay()].rangeEnd.time)}</div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 title mb-4">
                Price breakdown
            </div>
            <div className="col-12 sub-title">
                <div className="float-start mb-2">
                    Payable locally
                </div>
                <div className="float-end">
                    total
                </div>
            </div>
            <div className="col-12 small-text fw-bold border-bottom-divider">
                <div className="float-start">
                    Car price for {rate.package.rentalDurationWithGracePeriod} days
                </div>
                <div className="float-end">
                    {currencyToSymbol(rate.package.vehiclePrice.total.display.currency, rate.package.vehiclePrice.total.display.amount)}
                </div>
            </div>
            {selectedExtras && Object.keys(selectedExtras).map((code, index) => (
                <div className="col-12" key={index}>
                    <div className="float-start">
                        {rate.package.extras.find(extra => extra.code === code).name} (x{selectedExtras[code]?.quantity})
                    </div>
                    <div className="float-end">
                        {currencyToSymbol(rate.package.extras.find(extra => extra.code === code).rentalPrice.display.currency, calculateTotalPriceExtras(code))}
                    </div>
                </div>
            ))}
            <div className="col-12 total mb-lg-4 mb-5">
                <div className="float-start">
                    Booking total
                </div>
                <div className="float-end">
                    {currencyToSymbol(rate.package.vehiclePrice.total.display.currency, calculateTotalPrice())}
                </div>
            </div>
        </div>
    </div>
</div>)
}
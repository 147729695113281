import React, { useEffect, useState } from 'react';

export default function SearchFilters({ availability, setFilters }) {
    const [transmission, setTransmission] = useState({ Automatic: false, Manual: false });
    const [carGroup, setCarGroup] = useState({});
    
    useEffect(() => {
        if (availability.rates && availability.rates.length > 0) {
            let car_types = availability.rates.map(item => item.vehicle.type);
            car_types = [...new Set(car_types)];
            
            // Initialize carGroup state
            const initialCarGroupState = car_types.reduce((acc, type) => {
                acc[type] = false;
                return acc;
            }, {});
            
            setCarGroup(initialCarGroupState);
        }
    }, [availability]);

    const handleTransmissionChange = (type) => {
        const updatedTransmission = { ...transmission, [type]: !transmission[type] };
        setTransmission(updatedTransmission);
        updateFilters(updatedTransmission, carGroup);
    };

    const handleCarGroupChange = (type) => {
        const updatedCarGroup = { ...carGroup, [type]: !carGroup[type] };
        setCarGroup(updatedCarGroup);
        updateFilters(transmission, updatedCarGroup);
    };

    const updateFilters = (transmission, carGroup) => {
        const filters = {
            transmission: Object.entries(transmission).filter(([key, value]) => value).map(([key]) => key),
            carGroup: Object.entries(carGroup).filter(([key, value]) => value).map(([key]) => key)
        };
        setFilters(filters);
    };

    const clearTransmission = () => {
        const updatedTransmission = Object.keys(transmission).reduce((acc, type) => {
            acc[type] = false;
            return acc;
        }, {});
        setTransmission(updatedTransmission);
        updateFilters(updatedTransmission, carGroup);
    }

    const clearCarGroups = () => {
        const updatedCarGroup = Object.keys(carGroup).reduce((acc, type) => {
            acc[type] = false;
            return acc;
        }, {});
        setCarGroup(updatedCarGroup);
        updateFilters(transmission, updatedCarGroup);
    }

    const clearAll = () => {
        clearTransmission();
        clearCarGroups();
        updateFilters(transmission, carGroup);
    }

    return (
        availability.rates && availability.rates.length > 0 && (
            <div className="container-fluid filter-wrapper mt-5">
                <div className="row">
                    <div className="col-12">
                        <div className="row header mb-3">
                            <div className="col-6 fw-bold ps-0 pb-2">Transmission</div>
                            <div className="col-6 text-end pe-0 pb-2 clear" onClick={() => clearTransmission()}>Clear</div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12 pb-2">
                                <label>
                                    <input type="checkbox" checked={transmission.Automatic} onChange={() => handleTransmissionChange('Automatic')} />
                                    <div className='fake-input pe-3 mt-1'></div>
                                    Automatic
                                </label>
                            </div>
                            <div className="col-12">
                                <label>
                                    <input type="checkbox" checked={transmission.Manual} onChange={() => handleTransmissionChange('Manual')} />
                                    <div className='fake-input pe-3 mt-1'></div>
                                    Manual
                                </label>
                            </div>
                        </div>
                        <div className="row header mb-3">
                            <div className="col-6 fw-bold ps-0 pb-2">Car group</div>
                            <div className="col-6 text-end pe-0 pb-2 clear" onClick={() => clearCarGroups()}>Clear</div>
                        </div>
                        <div className="row mb-5">
                            {Object.keys(carGroup).map((type, index) => (
                                <div className="col-12 pb-2" key={index}>
                                    <label>
                                        <input type="checkbox" checked={carGroup[type]} onChange={() => handleCarGroupChange(type)} />
                                        <div className='fake-input pe-3 mt-1'></div>
                                        {type}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <button className="clear-all" onClick={() => {clearAll()}}>Clear all filters</button>
                    </div>
                </div>
            </div>
        )
    );
}
import accounting from 'accounting';

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export const formatNumber = (num) => {
  return accounting.formatMoney(+num, '', 0, ".", ",")
}

export const getAnchor = () => {
  return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
}

export const setAnchor = (anchor) => {
  window.location.hash = anchor;
}

export const removeAnchor = () => {
  history.replaceState({}, document.title, ".");
}

export const formatDate = (date) => {
  const pad = (num) => num.toString().padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);  // getMonth() returns a zero-based index
  const day = pad(date.getDate());

  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}:00`;
}

export const convertArgMapListToQueryString = (args) => {
  let paramsString = '?'
  if (args !== undefined) {
    for (const [k, v] of Object.entries(args)) {
      if (v !== null && v !== undefined && v !== '') {
        paramsString += `${k}=${v}&`
      }
    }
  }
  return paramsString
}

export const currencyToSymbol = (currency, amount) => {
  switch (currency) {
    case 'EUR':
      return `€${amount}`;
    case 'GBP':
      return `£${amount}`;
    case 'USD':
      return `$${amount}`;
    case 'ISK':
      return `${amount} kr`;
    default:
      return currency;
  }
}

export const verboseMonth = (month) => {
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return months[month];
}

export const verboseDay = (day) => {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return days[day];
}

export const daysBetween = (date1, date2) => {
  var diff = Math.floor((date2 - date1) / 86400000);
  return diff;
}

export const getFutureDate = (days) => {
  const result = new Date(); // Get the current date and time
  result.setDate(result.getDate() + days); // Add the specified number of days
  result.setHours(10, 0, 0, 0); // Set the time to 10:00:00

  return result;
}
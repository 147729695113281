import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailability, getRateInfo } from '../../slices/carrental';
import ResultsList from '../../components/results-list';
import SearchFilters from '../../components/search-filters';
import SearchWidget from '../../components/search-widget';
import { currencyToSymbol, verboseDay, verboseMonth } from '../../utils';
import ResultsBook from '../../components/results-book';


export default function ResultsWidgetContainer(props) {
    const dispatch = useDispatch();

    const [selectedCar, setSelectedCar] = useState(null);
    const [filters, setFilters] = useState({ transmission: [], carGroup: [] });
    const availability = useSelector(state => state.carrental.availability);
    const rate = useSelector(state => state.carrental.rateInfo);

    const params = {
        pickup: props.pickup,
        pickupBranch: props.pickupBranch,
        pickupTime: props.pickupTime,
        dropoff: props.dropoff,
        dropoffBranch: props.dropoffBranch,
        dropoffTime: props.dropoffTime,
    }

    useEffect(() => {
        dispatch(getAvailability(params));
    }, []);

    useEffect(() => {
        selectedCar && dispatch(getRateInfo({ rateReference: selectedCar.packages[0].rateReference }));
    }, [selectedCar]);

    return (
        rate ? (
           <ResultsBook rate={rate} />
        ) : (
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pl-lg-0 col-12">
                        <SearchWidget hideOrangeBackground={true} {...props} />
                        <SearchFilters availability={availability} setFilters={setFilters} />
                    </div >
                    <div className="col-lg-8 col-12" id="results-widget">
                        <ResultsList availability={availability} setSelectedCar={setSelectedCar} filters={filters} />
                    </div>
                </div >
            </div >
        )
    );
}
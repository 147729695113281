import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { currencyToSymbol, verboseMonth, verboseDay } from '../../utils';
import { createBooking } from '../../slices/carrental';
import OverviewSidebar from './overview-sidebar';
import ReactGA from 'react-ga4';
import { TELEPHONE_CODES } from '../../const';
import Select from 'react-select';

export default function ResultsBook({ rate }) {
    const dispatch = useDispatch();
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const reservNum = useSelector(state => state.carrental.booking?.reservNum);
    const [selectedExtras, setSelectedExtras] = useState({});
    const [customerInfo, setCustomerInfo] = useState({});
    const [bookingConfirmed, setBookingConfirmed] = useState(false);

    const calculateTotalPriceExtras = (code) => {
        if (selectedExtras[code]) {
            return selectedExtras[code].quantity * selectedExtras[code].amount;
        }
        return 0;
    };

    const calculateTotalPrice = () => {
        let total = rate.package.vehiclePrice.total.display.amount;
        if (selectedExtras) {
            Object.keys(selectedExtras).forEach(code => {
                total += calculateTotalPriceExtras(code);
            });
        }
        return total.toFixed(2);
    };

    const handleQuantityChange = (code, quantity, amount) => {
        const newSelection = {
            ...selectedExtras,
            [code]: {
                quantity: parseInt(quantity),
                amount: amount
            }
        };
        setSelectedExtras(newSelection);
    };

    const options = TELEPHONE_CODES.map((code) => ({
        value: code.phone_code,
        label: `(+${code.phone_code.length === 4 ? code.phone_code.slice(0, 1) + "-" + code.phone_code.slice(1) : code.phone_code}) ${code.name}`
    }));

    const getFullName = () => {
        return `${customerInfo.titleId === 1 ? 'Mr.' : customerInfo.titleId === 2 ? 'Mrs.' : 'Ms.'} ${customerInfo.firstName} ${customerInfo.lastName}`
    }

    const submitForm = (data) => {
        const payload = {
            rateReference: rate.package.rateReference,
            lang: "en-gb",
            type: "Booking",
            customer: {
                titleId: parseInt(data.titleId),
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: `+${data.phonePrefix.value} ${data.phone}`,
            },
            extras: Object.keys(selectedExtras).map(code => ({
                code: code,
                quantity: selectedExtras[code].quantity
            })),
            payment: {
                method: "Async"
            }
        };
        setCustomerInfo(payload.customer);
        dispatch(createBooking(payload)).then((response) => {
            setBookingConfirmed(true)
        });
    };

    useEffect(() => {
        if (bookingConfirmed) {
            ReactGA.send({hitType: "pageview", page: "/confirmation"})
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id: reservNum,
                currency: rate.package.vehiclePrice.total.display.currency,
                value: calculateTotalPrice(),
                payment_type: "Credit Card",
                items: [
                {
                    item_name: "Car Rental",
                    index: 0,
                    item_category: "Car Rental",
                    price: rate.package.vehiclePrice.total.display.amount,
                    quantity: 1
                },
                ...Object.keys(selectedExtras).map((code, index) => ({
                    item_name: rate.package.extras.find(extra => extra.code === code).name,
                    index: index + 1,
                    item_category: "Extra",
                    price: selectedExtras[code].amount,
                    quantity: selectedExtras[code].quantity
                }))
                ]
            }
            });
        }
    }, [bookingConfirmed]);

    return (
        <div className="container results-list">
            <div className="row">
                <OverviewSidebar rate={rate} selectedExtras={selectedExtras} />
                { bookingConfirmed ? 
                    <div className="col-lg-8 col-12 booking-confirmation" id="results-widget">
                        <h1 className="mb-4 mt-lg-0 mt-5">Your booking is confirmed.</h1>
                        <h4>
                            <strong>Check your e-mail</strong> for your booking confirmation. <br/>
                            <span>Your reference number is #{reservNum}.</span>
                        </h4>
                        <h5>Driver details</h5>
                        <hr/>
                        <div className="booking-breakdown d-flex flex-column">
                            <div className="row"> <span>Name:</span> { getFullName() }</div>
                            <div className="row"> <span>E-mail:</span> {customerInfo.email}</div>
                            <div className="row"> <span>Phone: </span> {customerInfo.phone}</div>
                        </div>
                        <h5>Paid locally</h5>
                        <hr/>
                        <div className="booking-breakdown d-flex flex-column">
                            <div className="row"> <span>Car price for {rate.package.rentalDurationWithGracePeriod} days:</span>  {currencyToSymbol(rate.package.vehiclePrice.total.display.currency, calculateTotalPrice())}</div>
                            <div className="row"> <span>Paid locally:</span>{currencyToSymbol(rate.package.vehiclePrice.total.display.currency, calculateTotalPrice())}</div>
                            <div className="row text-center mb-3"><strong>Total: {currencyToSymbol(rate.package.vehiclePrice.total.display.currency, calculateTotalPrice())}</strong></div>
                            <div className="col-12 text-center price-disclaimer mb-4">
                            The amount displayed in EUR is an estimate based on today's exchange rate. Your card provider may charge you a foreign exchange fee.
                            </div>
                        </div>
                        <h5>What next?</h5>
                        <hr/>
                        <ul>
                            <li> Please <strong>check your e-mail</strong> for booking confirmation and your rental voucher.</li>
                            <li> Your voucher will contain <strong>all information about your reservation</strong> and car rental supplier.</li>
                            <li> <strong>Don't forget to bring your identification documents and full driving licence when you pick up your vehicle.</strong></li>
                            <li> We <strong>recommend printing your voucher and bringing it with you</strong> when you pick up your vehicle.</li>
                            <li> <strong>Read the Terms and Conditions carefully</strong> to avoid misunderstandings.</li>
                            <li> If you have any questions, <strong>please feel free to contact us.</strong></li>
                        </ul>
                    </div> 
                
                :
                    <div className="col-lg-8 col-12" id="results-widget">
                        <h1 className="mb-4 mt-lg-0 mt-5">What suits you?</h1>
                        <div className="sub-heading border-bottom-divider">Extras - payable locally</div>

                        {rate.package.extras.map((extra, index) => (
                            <div className="row extra-container mb-2" key={index}>
                                <div className="col-2 img-extra-container align-items-center">
                                    <img src={extra.images[0].url} className="img-fluid" alt={extra.name} />
                                </div>

                                <div className="col-4">
                                    <p className="extra-description">{extra.name}</p>
                                    <p className="extra-total">{extra.description}</p>
                                </div>
                                <div className="col-4">
                                    <p className="extra-description"> {currencyToSymbol(extra.rentalPrice.display.currency, extra.rentalPrice.display.amount)} per item. </p>
                                    <p className="extra-total">Total price {currencyToSymbol(extra.rentalPrice.display.currency, calculateTotalPriceExtras(extra.code))}</p>
                                </div>

                                <div className="col-2">
                                    <select className="drop-down small-text"
                                        value={selectedExtras[extra.code]?.quantity || 0}
                                        onChange={(e) => handleQuantityChange(extra.code, e.target.value, extra.rentalPrice.display.amount)}
                                    >
                                        {[...Array(extra.maxQuantity + 1).keys()].map(quantity => (
                                            <option key={quantity} value={quantity}>
                                                {quantity}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ))}
                        <div className="sub-heading pt-5 pb-4">Driver information</div>
                        <form onSubmit={handleSubmit(submitForm)} className="form mb-4 pb-4">
                            <div className="row">
                                <div className="col-3 mb-3">
                                    <Controller
                                        name="titleId"
                                        control={control}
                                        rules={{ required: "Title is required" }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <select className="form-control" {...field}>
                                                    <option value="">Select title</option>
                                                    <option value="1">Mr.</option>
                                                    <option value="2">Mrs.</option>
                                                    <option value="3">Ms.</option>
                                                </select>
                                                {error && <p className="text-danger">{error.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 mb-3">
                                    <label className="sub-title">Given Name:</label>
                                    <input {...register("firstName", { required: "Given Name is required" })} type="text" className="form-control form-input" />
                                    {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
                                </div>
                                <div className="col-6">
                                    <label className="sub-title">Family Name:</label>
                                    <input {...register("lastName", { required: "Family Name is required" })} type="text" className="form-control" />
                                    {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <label className="sub-title">Email:</label>
                                    <input {...register("email", { required: "Email is required" })} type="text" className="form-control" />
                                    {errors.email && <p className="text-danger">{errors.email.message}</p>}
                                </div>
                                <div className="col-2">
                                    <label className="sub-title">Phone number:</label>
                                    <Controller
                                        name="phonePrefix"
                                        control={control}
                                        rules={{ required: "Phone prefix is required" }}
                                        defaultValue={options.find(option => option.value === "354")}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                <Select
                                                    {...field}
                                                    options={options}
                                                    classNamePrefix="react-select"
                                                    value={options.find(option => option.value === field.value)}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                    styles={{
                                                        menu: (base) => ({
                                                            ...base,
                                                            width: "max-content",
                                                            minWidth: "100%"
                                                       })
                                                    }}
                                                />
                                                {error && <p className="text-danger">{error.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="col-4">
                                    <label></label>
                                    <input {...register("phone", { required: "Phone number is required" })} className="form-control" type="text" />
                                    {errors.phone && <p className="text-danger">{errors.phone.message}</p>}
                                </div>
                            </div>
                            <div className="row payable pt-3">
                                <div className="col-12 d-flex justify-content-center align-items-center mb-3 border-bottom-divider border-top-divider pt-3 pb-3">
                                    <div className="col-6 payable-lable payable-lable-divider pe-2">
                                        Payable locally:
                                        <span className="fw-bold text-end payable-price">{currencyToSymbol(rate.package.vehiclePrice.total.display.currency, calculateTotalPrice())} </span>
                                    </div>
                                    <div className="col-6 payable-lable ps-2">
                                        Payable now:
                                        <span className="fw-bold payable-price"> € 0.00 </span>
                                    </div>
                                </div>
                                <div className="col-12 text-center pb-4 pt-2 border-bottom-divider payable-lable">
                                    Total:
                                    <span className="fw-bold"> {currencyToSymbol(rate.package.vehiclePrice.total.display.currency, calculateTotalPrice())}</span>
                                </div>
                                <div className="col-12 text-center price-disclaimer mb-5">
                                    The amount displayed in EUR is an estimate based on today's exchange rate. Your card provider may charge you a foreign exchange fee.
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 d-flex">
                                    <label className="d-flex align-items-center">
                                        <input type="checkbox" {...register("agree_toc", { required: "You must agree with the Terms and Conditions" })} />
                                        <div className='fake-input pe-3 mt-1'></div>
                                        I agree with the Terms and Conditions
                                    </label>
                                    {errors.agree_toc && <p className="text-danger">{errors.agree_toc.message}</p>}
                                </div>
                                <div className="col-6 book-btn">
                                    <button type="submit" className="btn btn-primary">Book now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </div>
        </div>
    )
}
export const API_BASE_PATH = "/api/";
export const TELEPHONE_CODES = [
    {"id":"af","name":"Afghanistan","phone_code":"93"},
    {"id":"ax","name":"\u00c5land Islands","phone_code":"358"},
    {"id":"al","name":"Albania","phone_code":"355"},
    {"id":"dz","name":"Algeria","phone_code":"213"},
    {"id":"as","name":"American Samoa","phone_code":"1684"},
    {"id":"ad","name":"Andorra","phone_code":"376"},
    {"id":"ao","name":"Angola","phone_code":"244"},
    {"id":"ai","name":"Anguilla","phone_code":"1264"},
    {"id":"aq","name":"Antarctica","phone_code":"672"},
    {"id":"ag","name":"Antigua and Barbuda","phone_code":"1268"},
    {"id":"ar","name":"Argentina","phone_code":"54"},
    {"id":"am","name":"Armenia","phone_code":"374"},
    {"id":"aw","name":"Aruba","phone_code":"297"},
    {"id":"au","name":"Australia","phone_code":"61"},
    {"id":"at","name":"Austria","phone_code":"43"},
    {"id":"az","name":"Azerbaijan","phone_code":"994"},
    {"id":"bs","name":"Bahamas","phone_code":"1242"},
    {"id":"bh","name":"Bahrain","phone_code":"973"},
    {"id":"bd","name":"Bangladesh","phone_code":"880"},
    {"id":"bb","name":"Barbados","phone_code":"1246"},
    {"id":"by","name":"Belarus","phone_code":"375"},
    {"id":"be","name":"Belgium","phone_code":"32"},
    {"id":"bz","name":"Belize","phone_code":"501"},
    {"id":"bj","name":"Benin","phone_code":"229"},
    {"id":"bm","name":"Bermuda","phone_code":"1441"},
    {"id":"bt","name":"Bhutan","phone_code":"975"},
    {"id":"bo","name":"Bolivia","phone_code":"591"},
    {"id":"bq","name":"Bonaire, Sint Eustatius and Saba","phone_code":""},
    {"id":"ba","name":"Bosnia and Herzegovina","phone_code":"387"},
    {"id":"bw","name":"Botswana","phone_code":"267"},
    {"id":"bv","name":"Bouvet Island","phone_code":"47"},
    {"id":"br","name":"Brazil","phone_code":"55"},
    {"id":"io","name":"British Indian Ocean Territory","phone_code":"246"},
    {"id":"bn","name":"Brunei","phone_code":"673"},
    {"id":"bg","name":"Bulgaria","phone_code":"359"},
    {"id":"bf","name":"Burkina Faso","phone_code":"226"},
    {"id":"bi","name":"Burundi","phone_code":"257"},
    {"id":"cv","name":"Cabo Verde","phone_code":"238"},
    {"id":"kh","name":"Cambodia","phone_code":"855"},
    {"id":"cm","name":"Cameroon","phone_code":"237"},
    {"id":"ca","name":"Canada","phone_code":"1"},
    {"id":"ky","name":"Cayman Islands","phone_code":"1345"},
    {"id":"cf","name":"Central African Republic","phone_code":"236"},
    {"id":"td","name":"Chad","phone_code":"235"},
    {"id":"cl","name":"Chile","phone_code":"56"},
    {"id":"cn","name":"China","phone_code":"86"},
    {"id":"cx","name":"Christmas Island","phone_code":"61"},
    {"id":"cc","name":"Cocos (Keeling) Islands","phone_code":"61"},
    {"id":"co","name":"Colombia","phone_code":"57"},
    {"id":"km","name":"Comoros","phone_code":"269"},
    {"id":"cd","name":"Congo, Democratic Republic of the","phone_code":"243"},
    {"id":"cg","name":"Congo, Republic of the","phone_code":"242"},
    {"id":"ck","name":"Cook Islands","phone_code":"682"},
    {"id":"cr","name":"Costa Rica","phone_code":"506"},
    {"id":"ci","name":"C\u00f4te d'Ivoire","phone_code":"225"},
    {"id":"hr","name":"Croatia","phone_code":"385"},
    {"id":"cu","name":"Cuba","phone_code":"53"},
    {"id":"cw","name":"Cura\u00e7ao","phone_code":""},
    {"id":"cy","name":"Cyprus","phone_code":"357"},
    {"id":"cz","name":"Czech Republic","phone_code":"420"},
    {"id":"dk","name":"Denmark","phone_code":"45"},
    {"id":"dj","name":"Djibouti","phone_code":"253"},
    {"id":"dm","name":"Dominica","phone_code":"1767"},
    {"id":"do","name":"Dominican Republic","phone_code":"1809"},
    {"id":"ec","name":"Ecuador","phone_code":"593"},
    {"id":"eg","name":"Egypt","phone_code":"20"},
    {"id":"sv","name":"El Salvador","phone_code":"503"},
    {"id":"gq","name":"Equatorial Guinea","phone_code":"240"},
    {"id":"er","name":"Eritrea","phone_code":"291"},
    {"id":"ee","name":"Estonia","phone_code":"372"},
    {"id":"sz","name":"Eswatini","phone_code":"268"},
    {"id":"et","name":"Ethiopia","phone_code":"251"},
    {"id":"fk","name":"Falkland Islands (Malvinas)","phone_code":"500"},
    {"id":"fo","name":"Faroe Islands","phone_code":"298"},
    {"id":"fj","name":"Fiji","phone_code":"679"},
    {"id":"fi","name":"Finland","phone_code":"358"},
    {"id":"fr","name":"France","phone_code":"33"},
    {"id":"gf","name":"French Guiana","phone_code":"594"},
    {"id":"pf","name":"French Polynesia","phone_code":"689"},
    {"id":"tf","name":"French Southern Territories","phone_code":"262"},
    {"id":"ga","name":"Gabon","phone_code":"241"},
    {"id":"gm","name":"Gambia","phone_code":"220"},
    {"id":"ge","name":"Georgia","phone_code":"995"},
    {"id":"de","name":"Germany","phone_code":"49"},
    {"id":"gh","name":"Ghana","phone_code":"233"},
    {"id":"gi","name":"Gibraltar","phone_code":"350"},
    {"id":"gr","name":"Greece","phone_code":"30"},
    {"id":"gl","name":"Greenland","phone_code":"299"},
    {"id":"gd","name":"Grenada","phone_code":"1473"},
    {"id":"gp","name":"Guadeloupe","phone_code":"590"},
    {"id":"gu","name":"Guam","phone_code":"1671"},
    {"id":"gt","name":"Guatemala","phone_code":"502"},
    {"id":"gg","name":"Guernsey","phone_code":"699"},
    {"id":"gn","name":"Guinea","phone_code":"224"},
    {"id":"gw","name":"Guinea-Bissau","phone_code":"245"},
    {"id":"gy","name":"Guyana","phone_code":"592"},
    {"id":"ht","name":"Haiti","phone_code":"509"},
    {"id":"hm","name":"Heard Island and McDonald Islands","phone_code":"61"},
    {"id":"va","name":"Holy See","phone_code":"39"},
    {"id":"hn","name":"Honduras","phone_code":"504"},
    {"id":"hk","name":"Hong Kong","phone_code":"852"},
    {"id":"hu","name":"Hungary","phone_code":"36"},
    {"id":"is","name":"Iceland","phone_code":"354"},
    {"id":"in","name":"India","phone_code":"91"},
    {"id":"id","name":"Indonesia","phone_code":"62"},
    {"id":"ir","name":"Iran, Islamic Republic of","phone_code":"98"},
    {"id":"iq","name":"Iraq","phone_code":"964"},
    {"id":"ie","name":"Ireland","phone_code":"353"},
    {"id":"im","name":"Isle of Man","phone_code":"44"},
    {"id":"il","name":"Israel","phone_code":"972"},
    {"id":"it","name":"Italy","phone_code":"39"},
    {"id":"jm","name":"Jamaica","phone_code":"1876"},
    {"id":"jp","name":"Japan","phone_code":"81"},
    {"id":"je","name":"Jersey","phone_code":"44"},
    {"id":"jo","name":"Jordan","phone_code":"962"},
    {"id":"kz","name":"Kazakhstan","phone_code":"7"},
    {"id":"ke","name":"Kenya","phone_code":"254"},
    {"id":"ki","name":"Kiribati","phone_code":"686"},
    {"id":"xk","name":"Kosovo","phone_code":""},
    {"id":"kw","name":"Kuwait","phone_code":"965"},
    {"id":"kg","name":"Kyrgyzstan","phone_code":"996"},
    {"id":"la","name":"Lao People's Democratic Republic","phone_code":"856"},
    {"id":"lv","name":"Latvia","phone_code":"371"},
    {"id":"lb","name":"Lebanon","phone_code":"961"},
    {"id":"ls","name":"Lesotho","phone_code":"266"},
    {"id":"lr","name":"Liberia","phone_code":"231"},
    {"id":"ly","name":"Libya","phone_code":"218"},
    {"id":"li","name":"Liechtenstein","phone_code":"423"},
    {"id":"lt","name":"Lithuania","phone_code":"370"},
    {"id":"lu","name":"Luxembourg","phone_code":"352"},
    {"id":"mo","name":"Macao","phone_code":"853"},
    {"id":"mg","name":"Madagascar","phone_code":"261"},
    {"id":"mw","name":"Malawi","phone_code":"265"},
    {"id":"my","name":"Malaysia","phone_code":"60"},
    {"id":"mv","name":"Maldives","phone_code":"960"},
    {"id":"ml","name":"Mali","phone_code":"223"},
    {"id":"mt","name":"Malta","phone_code":"356"},
    {"id":"mh","name":"Marshall Islands","phone_code":"692"},
    {"id":"mq","name":"Martinique","phone_code":"596"},
    {"id":"mr","name":"Mauritania","phone_code":"222"},
    {"id":"mu","name":"Mauritius","phone_code":"230"},
    {"id":"yt","name":"Mayotte","phone_code":"262"},
    {"id":"mx","name":"Mexico","phone_code":"52"},
    {"id":"fm","name":"Micronesia, Federated States of","phone_code":"691"},
    {"id":"md","name":"Moldova","phone_code":"373"},
    {"id":"mc","name":"Monaco","phone_code":"377"},
    {"id":"mn","name":"Mongolia","phone_code":"976"},
    {"id":"me","name":"Montenegro","phone_code":"382"},
    {"id":"ms","name":"Montserrat","phone_code":"1664"},
    {"id":"ma","name":"Morocco","phone_code":"212"},
    {"id":"mz","name":"Mozambique","phone_code":"258"},
    {"id":"mm","name":"Myanmar","phone_code":"95"},
    {"id":"na","name":"Namibia","phone_code":"264"},
    {"id":"nr","name":"Nauru","phone_code":"674"},
    {"id":"np","name":"Nepal","phone_code":"977"},
    {"id":"nl","name":"Netherlands","phone_code":"31"},
    {"id":"an","name":"Netherlands Antilles","phone_code":"599"},
    {"id":"nc","name":"New Caledonia","phone_code":"687"},
    {"id":"nz","name":"New Zealand","phone_code":"64"},
    {"id":"ni","name":"Nicaragua","phone_code":"505"},
    {"id":"ne","name":"Niger","phone_code":"227"},
    {"id":"ng","name":"Nigeria","phone_code":"234"},
    {"id":"nu","name":"Niue","phone_code":"683"},
    {"id":"nf","name":"Norfolk Island","phone_code":"672"},
    {"id":"kp","name":"North Korea","phone_code":"850"},
    {"id":"mk","name":"North Macedonia","phone_code":"389"},
    {"id":"mp","name":"Northern Mariana Islands","phone_code":"1670"},
    {"id":"no","name":"Norway","phone_code":"47"},
    {"id":"om","name":"Oman","phone_code":"968"},
    {"id":"pk","name":"Pakistan","phone_code":"92"},
    {"id":"pw","name":"Palau","phone_code":"680"},
    {"id":"ps","name":"Palestine, State of","phone_code":"970"},
    {"id":"pa","name":"Panama","phone_code":"507"},
    {"id":"pg","name":"Papua New Guinea","phone_code":"675"},
    {"id":"py","name":"Paraguay","phone_code":"595"},
    {"id":"pe","name":"Peru","phone_code":"51"},
    {"id":"ph","name":"Philippines","phone_code":"63"},
    {"id":"pn","name":"Pitcairn","phone_code":"870"},
    {"id":"pl","name":"Poland","phone_code":"48"},
    {"id":"pt","name":"Portugal","phone_code":"351"},
    {"id":"pr","name":"Puerto Rico","phone_code":"870"},
    {"id":"qa","name":"Qatar","phone_code":"974"},
    {"id":"re","name":"R\u00e9union","phone_code":"262"},
    {"id":"ro","name":"Romania","phone_code":"40"},
    {"id":"ru","name":"Russian Federation","phone_code":"7"},
    {"id":"rw","name":"Rwanda","phone_code":"250"},
    {"id":"bl","name":"Saint Barth\u00e9lemy","phone_code":"590"},
    {"id":"sh","name":"Saint Helena, Ascension and Tristan da Cunha","phone_code":"290"},
    {"id":"kn","name":"Saint Kitts and Nevis","phone_code":"1869"},
    {"id":"lc","name":"Saint Lucia","phone_code":"1758"},
    {"id":"mf","name":"Saint Martin","phone_code":"1599"},
    {"id":"pm","name":"Saint Pierre and Miquelon","phone_code":"508"},
    {"id":"vc","name":"Saint Vincent and the Grenadines","phone_code":"1784"},
    {"id":"ws","name":"Samoa","phone_code":"685"},
    {"id":"sm","name":"San Marino","phone_code":"378"},
    {"id":"st","name":"S\u00e3o Tom\u00e9 and Pr\u00edncipe","phone_code":"239"},
    {"id":"sa","name":"Saudi Arabia","phone_code":"966"},
    {"id":"sn","name":"Senegal","phone_code":"221"},
    {"id":"rs","name":"Serbia","phone_code":"381"},
    {"id":"sc","name":"Seychelles","phone_code":"248"},
    {"id":"sl","name":"Sierra Leone","phone_code":"232"},
    {"id":"sg","name":"Singapore","phone_code":"65"},
    {"id":"sx","name":"Sint Maarten","phone_code":""},
    {"id":"sk","name":"Slovakia","phone_code":"421"},
    {"id":"si","name":"Slovenia","phone_code":"386"},
    {"id":"sb","name":"Solomon Islands","phone_code":"677"},
    {"id":"so","name":"Somalia","phone_code":"253"},
    {"id":"za","name":"South Africa","phone_code":"27"},
    {"id":"gs","name":"South Georgia and the South Sandwich Islands","phone_code":"500"},
    {"id":"kr","name":"South Korea","phone_code":"82"},
    {"id":"ss","name":"South Sudan","phone_code":""},
    {"id":"es","name":"Spain","phone_code":"34"},
    {"id":"lk","name":"Sri Lanka","phone_code":"94"},
    {"id":"sd","name":"Sudan","phone_code":"249"},
    {"id":"sr","name":"Suriname","phone_code":"597"},
    {"id":"sj","name":"Svalbard and Jan Mayen","phone_code":"47"},
    {"id":"se","name":"Sweden","phone_code":"46"},
    {"id":"ch","name":"Switzerland","phone_code":"41"},
    {"id":"sy","name":"Syrian Arab Republic","phone_code":"963"},
    {"id":"tw","name":"Taiwan, Province of China","phone_code":"886"},
    {"id":"tj","name":"Tajikistan","phone_code":"992"},
    {"id":"tz","name":"Tanzania","phone_code":"255"},
    {"id":"th","name":"Thailand","phone_code":"66"},
    {"id":"tl","name":"Timor-Leste","phone_code":"670"},
    {"id":"tg","name":"Togo","phone_code":"228"},
    {"id":"tk","name":"Tokelau","phone_code":"690"},
    {"id":"to","name":"Tonga","phone_code":"676"},
    {"id":"tt","name":"Trinidad and Tobago","phone_code":"1868"},
    {"id":"tn","name":"Tunisia","phone_code":"216"},
    {"id":"tr","name":"Turkey","phone_code":"90"},
    {"id":"tm","name":"Turkmenistan","phone_code":"993"},
    {"id":"tc","name":"Turks and Caicos Islands","phone_code":"1649"},
    {"id":"tv","name":"Tuvalu","phone_code":"688"},
    {"id":"ug","name":"Uganda","phone_code":"256"},
    {"id":"ua","name":"Ukraine","phone_code":"380"},
    {"id":"ae","name":"United Arab Emirates","phone_code":"971"},
    {"id":"gb","name":"United Kingdom","phone_code":"44"},
    {"id":"us","name":"United States","phone_code":"1"},
    {"id":"um","name":"United States Minor Outlying Islands","phone_code":"699"},
    {"id":"uy","name":"Uruguay","phone_code":"598"},
    {"id":"uz","name":"Uzbekistan","phone_code":"998"},
    {"id":"vu","name":"Vanuatu","phone_code":"678"},
    {"id":"ve","name":"Venezuela","phone_code":"58"},
    {"id":"vn","name":"Vietnam","phone_code":"84"},
    {"id":"vg","name":"Virgin Islands (British)","phone_code":"1284"},
    {"id":"vi","name":"Virgin Islands (U.S.)","phone_code":"1340"},
    {"id":"wf","name":"Wallis and Futuna","phone_code":"681"},
    {"id":"eh","name":"Western Sahara","phone_code":"212"},
    {"id":"ye","name":"Yemen","phone_code":"967"},
    {"id":"zm","name":"Zambia","phone_code":"260"},
    {"id":"zw","name":"Zimbabwe","phone_code":"263"}
]


import axios from "axios";
import { getCookie } from "../../utils";
import { API_BASE_PATH, convertArgMapListToQueryString } from "../../const";

const getBranches = (args) => {
    return axios
        .get(API_BASE_PATH + 'branches', {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getAvailability = (args) => {
    return axios
        .get(API_BASE_PATH + 'availability?'+new URLSearchParams(args).toString(), {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getResidenceCountries = (args) => {
    return axios
        .get(API_BASE_PATH + 'residence-countries', {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getRateInfo = (args) => {
    return axios
    .get(API_BASE_PATH + `rateinfo?rate_reference=${args.rateReference}`, {
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
        }
    })
    .then((response) => {
        return response.data;
    });
};

const createBooking = (args) => {
    return axios
    .post(API_BASE_PATH + `booking`, args, {
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
        }
    })
    .then((response) => {
        return response.data;
    });
};


const carRentalService = {
    getBranches,
    getAvailability,
    getRateInfo,
    createBooking,
    getResidenceCountries,
};

export default carRentalService;
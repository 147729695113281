import React, {useEffect, useState} from 'react';
import { currencyToSymbol } from '../../utils';

export default function ResultsList({availability, setSelectedCar, filters}) {

    const [filteredRates, setFilteredRates] = useState(availability.rates);

    useEffect(() => {
        console.log(filters)
        let filtered_rates = availability.rates;
        if (filters.transmission.length > 0) {
            filtered_rates = filtered_rates.filter(rate => {
                return filters.transmission.includes(rate.vehicle.transmission)
            })
        }
        if (filters.carGroup.length > 0) {
            filtered_rates = filtered_rates.filter(rate => {
                return filters.carGroup.includes(rate.vehicle.type)
            })
        }
        setFilteredRates(filtered_rates);
    }, [filters, availability]);

    return (
        <>
                {availability.rates && availability.rates.length > 0 ? (
                    <>
                        <h1>We found <span>{filteredRates.length} cars</span> in <span>{availability.branches[availability.rates[0].pickUpBranchId].location.name}</span></h1>
                        {availability.rates.map((item, index) => {
                            if(filteredRates.includes(item)){
                            return (
                                <div className="row car mt-5" key={index}>
                                    <div className="col-12 mb-2">
                                        <div className="car-type">{item.vehicle.type}</div>
                                        <div className="car-name">{item.vehicle.name} <span>or similar</span></div>
                                    </div>
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col">
                                                <img src={item.vehicle.images[0].url} className="img-fluid col-10 mb-2" />
                                            </div>
                                        </div>
                                        <div className="row car-big-info">
                                            <div className="col-6 justify-content-center">
                                                <div className="car-seats fw-bold">{item.vehicle.seats}</div>
                                            </div>
                                            <div className="col-6">
                                                <div className="car-bigsuitcases fw-bold">{item.vehicle.bigSuitcases}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col car-small-info col-10">
                                                <span className="car-transmission">{item.vehicle.transmission}</span>
                                                <span className="car-doors">{item.vehicle.doors}</span>
                                                <span className="car-smallsuitcases">{item.vehicle.smallSuitcases}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 pt-2 bt">
                                        <div className="fw-bold">Pick-up location:</div>
                                        {availability.branches[item.pickUpBranchId].vehicleAt.name}
                                        <div className="fw-bold">Included in the rate:</div>
                                        {item.packages.map((pckage, index) => {
                                            return pckage.inclusions.map((inclusion, index) => {
                                                return (
                                                    <div key={index}>{inclusion.name}</div>
                                                )
                                            })
                                        })}
                                    </div>
                                    <div className="col-4 text-end pt-2 bt pe-0">
                                        <img className="supplier-logo" src={availability.branches[item.pickUpBranchId].supplier.images[0].url} />
                                        <div className="price-container"> 
                                            <div className="price">{currencyToSymbol(item.packages[0].vehiclePrice.total.display.currency, item.packages[0].vehiclePrice.total.display.amount)}</div>
                                            <div className="days">Price for {item.packages[0].rentalDurationWithGracePeriod} days</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-end pe-0 mb-4">
                                        <button className="btn btn-book" onClick={()=>setSelectedCar(item)}>Book now</button>
                                    </div>
                                </div>
                            )
                        }
                        })}
                    </>
                ) : (
                    <>
                    <h1>We are checking availability and crunching the numbers.</h1>
                    </>
                )
                }
        </>
    )
}
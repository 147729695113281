import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getBranches, getResidenceCountries } from '../../slices/carrental';
import DatePicker from "react-datepicker";
import { formatDate, getFutureDate } from '../../utils';

export default function SearchWidget(props) {
    const dispatch = useDispatch();
    const branches = useSelector(state => state.carrental.branches); // Adjust based on your state shape
    const residenceCountries = useSelector(state => state.carrental.residenceCountries); // Adjust based on your state shape
    const [pickupLocation, setPickupLocation] = useState(props.pickup);
    const [pickupDateTime, setPickupDateTime] = useState(getFutureDate(7));
    const [dropoffLocation, setDropoffLocation] = useState(props.dropoff);
    const [dropoffDateTime, setDropoffDateTime] = useState(getFutureDate(14));
    const { control, handleSubmit, watch, setValue, formState } = useForm(
        {
            defaultValues: {
                pickup: props.pickup || "",
                pickupBranch: parseInt(props.pickupBranch) || "",
                differentDropoff: props.pickupBranch !== props.dropoffBranch,
                dropoff: props.dropoff || "",
                dropoffBranch: parseInt(props.dropoffBranch) || "",
                pickupDate: props.pickupTime ? new Date(props.pickupTime) : getFutureDate(7),
                pickupTime: props.pickupTime ? new Date(props.pickupTime) : getFutureDate(7),
                dropoffDate: props.dropoffTime ? new Date(props.dropoffTime) : getFutureDate(14),
                dropoffTime: props.dropoffTime ? new Date(props.dropoffTime) : getFutureDate(14)
            },
            mode: 'all',
        }
    );

    const { isValid } = formState;

    // Watchers to observe changes
    const pickupDate = watch('pickupDate');
    const pickupTime = watch('pickupTime');
    const dropoffDate = watch('dropoffDate');
    const dropoffTime = watch('dropoffTime');

    // Function to combine date and time into a single Date object
    const combineDateAndTime = (date, time) => {
        date = new Date(date);
        time = new Date(time);
        const timeString = time.getHours() + ':' + time.getMinutes() + ':00';
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        return new Date(year, month, day, ...timeString.split(':').map(Number));
    };

    useEffect(() => {
        dispatch(getBranches());
        dispatch(getResidenceCountries());
    }, [dispatch]);

    const watchDifferentLocation = watch("differentDropoff", false);

    const onSubmit = data => {
        const params = {
            'pickup': data.pickup,
            'pickupBranch': data.pickupBranch,
            'pickupTime': formatDate(pickupDateTime),
            'dropoff': data.differentDropoff ? data.dropoff : data.pickup,
            'dropoffBranch': data.differentDropoff ? data.dropoffBranch : data.pickupBranch,
            'dropoffTime': formatDate(dropoffDateTime)
        };
        window.location.href = `/carresults?${Object.keys(params).map(key => key + '=' + params[key]).join('&')}`;
    };

    useEffect(() => {
        if (!watchDifferentLocation) {
            setValue("dropoff", "");
        }
    }, [watchDifferentLocation, setValue]);

    return (
        <div id="search-widget" className={`${props.hideOrangeBackground ? '' : 'showOrange'}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h1>WHERE TO?</h1>
                    {/* Pickup Location */}
                    <div>
                        <label>Pickup Location</label>
                        <Controller
                            name="pickup"
                            control={control}
                            defaultValue={props.pickup || ""}
                            rules={{ required: 'Pickup location is required' }}
                            render={({ field }) => (
                                <select {...field} onChange={(e) => {
                                    field.onChange(e);
                                    setPickupLocation(e.target.value);
                                }}>
                                    <option value="">Please select...</option>
                                    {Object.keys(branches).map((key) => (
                                        <option key={key} value={key}>
                                            {key}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                        <Controller
                            name="pickupBranch"
                            control={control}
                            defaultValue={props.pickupBranch}
                            rules={{ required: 'Pickup branch is required' }}
                            render={({ field }) => (
                                <select {...field}>
                                    <option value="">Select a branch...</option>
                                    {pickupLocation && branches[pickupLocation] && branches[pickupLocation].map((branch) => (
                                        <option key={branch.branch_id} value={branch.branch_id}>
                                            {branch.location_name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        />
                    </div>
                    {/* Different Dropoff Location */}
                    <div>
                        <Controller
                            name="differentDropoff"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <label className='d-flex align-items-center cursor-pointer mt-2'>
                                    <input type="checkbox" {...field} />
                                    <div className='fake-input'></div>
                                    Different Dropoff Location
                                </label>
                            )}
                        />
                    </div>
                    {/* Conditional Dropoff Location */}
                    {watchDifferentLocation && (
                        <div className="mt-3">
                            <label>Dropoff Location</label>
                            <div>
                                <Controller
                                    name="dropoff"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <select {...field} onChange={(e) => {
                                            field.onChange(e);
                                            setDropoffLocation(e.target.value);
                                        }}>
                                            <option value="">Please select...</option>
                                            {Object.keys(branches).map((key) => (
                                                <option key={key} value={key}>
                                                    {key}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                                <Controller
                                    name="dropoffBranch"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <select {...field}>
                                            <option value="">Select a branch...</option>
                                            {dropoffLocation && branches[dropoffLocation].map((branch) => (
                                                <option key={branch.branch_id} value={branch.branch_id}>
                                                    {branch.location_name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </div>
                        </div>
                    )}
                    {/* Pickup Time */}
                    <div className="mt-3">
                        <label>Pickup Time</label>
                        <div className='d-flex'>
                            <div className="date-container">
                                <Controller
                                    control={control}
                                    name="pickupDate"
                                    rules={{ required: 'Pickup date is required' }}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                                setPickupDateTime(combineDateAndTime(date, pickupTime));
                                                
                                            }}
                                            minDate={new Date()}
                                            dateFormat="MMMM d, yyyy"
                                        />
                                    )}
                                />
                            </div>
                            <div className='time-container'>
                                <Controller
                                    control={control}
                                    name="pickupTime"
                                    rules={{ required: 'Pickup time is required' }}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(time) => {
                                                field.onChange(time);
                                                setPickupDateTime(combineDateAndTime(pickupDate, time));
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Dropoff Time */}
                    <div>
                        <label>Dropoff Time</label>
                        <div className='d-flex'>
                            <div className='date-container'>
                                <Controller
                                    control={control}
                                    name="dropoffDate"
                                    rules={{ required: 'Drop-off date is required' }}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            className="date-container"
                                            onChange={(date) => {
                                                field.onChange(date);
                                                setDropoffDateTime(combineDateAndTime(date, dropoffTime));
                                            }}
                                            minDate={pickupDate}
                                            dateFormat="MMMM d, yyyy"
                                        />
                                    )}
                                />
                            </div>
                            <div className='time-container'>
                                <Controller
                                    control={control}
                                    name="dropoffTime"
                                    rules={{ required: 'Drop-off time is required' }}
                                    render={({ field }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={(time) => {
                                                field.onChange(time);
                                                setDropoffDateTime(combineDateAndTime(dropoffDate, time));
                                            }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30} // Time selection in 30 minute intervals
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Age Confirmation and Currency */}
                    <div className="mt-2">
                        <Controller
                            name="ageConfirmation"
                            control={control}
                            defaultValue={true}
                            render={({ field }) => (
                                <label className='d-flex align-items-top'>
                                    <input type="checkbox" {...field} checked={field.value} />
                                    <div className='fake-input pe-3 mt-1'></div>
                                    <span>I am <b>25-75</b> years old and a resident of <b>Iceland</b>. Display prices in <b></b>EUR.</span>
                                </label>
                            )}
                        />
                        {!watch("ageConfirmation", true) && (
                            <>
                                <Controller
                                    name="currency"
                                    control={control}
                                    defaultValue="EUR"
                                    render={({ field }) => (
                                        <select {...field} className="currency" >
                                            <option value="EUR">EUR</option>
                                            <option value="GBP">GBP</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    )}
                                />
                                <Controller
                                    name="age"
                                    control={control}
                                    defaultValue={50}
                                    render={({ field }) => (
                                        <input type="number" {...field}  />
                                    )}
                                />
                                <Controller
                                    name="residence"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <select {...field}>
                                            <option value="">Please select...</option>
                                            {residenceCountries.map((rc, i) => (
                                                <option key={i} value={rc.code}>
                                                    {rc.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                />
                            </>
                        )}
                    </div>
                    {/* Submit Button */}
                    <input type="submit" value="SEARCH" disabled={!isValid} />
                </div>
            </form>
        </div>
    );
}